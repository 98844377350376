import { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { axiosInstance } from '../../../network/config';
import { setLoggedIn } from '../../../Store/features/Login/loginSlice';
import { getQueryParams } from '../../../utils/getQueryParams';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { ID } = useParams();
  const location = useLocation();
  const params = useParams();

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [resetToken, setResetToken] = useState('');

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirmPassword: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });
  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    if (queryParams.ID) {
      setResetToken(queryParams.ID); // Use the token from URL parameters if available
    }
  }, [location.search]);

  return (
    <Formik
      initialValues={{ confirmPassword: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setLoading(true);
        axiosInstance
          .patch('/users/updatePassword', { ...values, token: resetToken }) // Include the token in the payload
          .then((res) => {
            setLoading(false);
            if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') toast.error(res.data.MESSAGE);
            toast.success(res.data.MESSAGE);
            navigate('/login', { replace: true }); // Redirect to login page
          })
          .catch((err) => {
            console.error('Error:', err);
            setLoading(false);
            toast.error(err.response.data.MESSAGE);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} mb={4}>
            <Typography sx={{ lineHeight: '0', margin: '0' }} variant="body1" gutterBottom>
              New Password
            </Typography>
            <TextField
              name="password"
              required
              value={values.password}
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />
            <Typography sx={{ lineHeight: '0', margin: '0' }} variant="body1" gutterBottom>
              Confirmation new Password
            </Typography>
            <TextField
              name="confirmPassword"
              variant="outlined"
              type={showConfirmPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <LoadingButton
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            sx={{
              color: 'white', // Text color
              backgroundColor: 'black', // Background color
              '&:hover': {
                backgroundColor: 'black', // Hover background color
              },
            }}
            disabled={!values.confirmPassword || !values.password}
            variant="contained"
          >
            Update Password
          </LoadingButton>
        </form>
      )}
    </Formik>
  );
}
